import _, { find, some } from "lodash";

import everythingAbout from "./tabs/everything-about/index.vue";
import description from "./tabs/description/index.vue";
import structure from "./tabs/description/index.vue";
import recommendation from "./tabs/description/index.vue";
import comments from "./tabs/comments/index.vue";
import comment from "./components/comment-card/index.vue";
import questions from "./tabs/questions/index.vue";
import slider from "./components/slider/index.vue";
import product from "../../components/product/index.vue";
import accordion from "./components/accordion/index.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { Carousel, Slide } from "vue-carousel";
import { getProductPriceTitle } from "@/helpers";

const CardTab = {
    EVERYTHING_ABOUT: 0,
    DESCRIPTION: 1,
    STRUCTURE: 2,
    RECOMMENDATION: 3,
    COMMENTS: 4,
    QUESTIONS: 5,
};

export default {
    name: "card",
    components: {
        everythingAbout,
        description,
        structure,
        recommendation,
        comment,
        comments,
        questions,
        slider,
        Carousel,
        Slide,
        product,
        accordion,
    },
    data() {
        return {
            CardTab,
            clientPrices: [],
            sameProducts: [],
            tabs: [],
            currentTab: CardTab.DESCRIPTION,
            selectedCount: 1,
            selectedProductPrice: {},
            leftoverType: null,
            images: [],
        };
    },
    watch: {
        selectedProductPrice: function() {
            this.addImageProduct();
        },
    },
    async created() {
        this.tabs = [
            {
                id: CardTab.EVERYTHING_ABOUT,
                title: "allAboutTheProduct",
            },
            {
                id: CardTab.DESCRIPTION,
                title: "description",
            },
            {
                id: CardTab.STRUCTURE,
                title: "warehouse",
            },
            {
                id: CardTab.RECOMMENDATION,
                title: "recommendations",
            },
            {
                id: CardTab.COMMENTS,
                title: 'comments',
            },
            {
              id: CardTab.QUESTIONS,
              title: "questionsAndAnswers",
            }
        ];
        await this.fetchProduct({
            slug: this.$route.params.slug,
            value: this.$route.query.value,
        });

        this.checkValue();

        await Promise.all([this.getProductPrice(), this.loadSameProducts()]);
    },
    computed: {
        ...mapGetters({
            productLoading: "card/productLoading",
            product: "card/product",
            isDistributor: "profile/isDistributor",
            isProfessional: "profile/isProfessional",
            isUserWithCode: "profile/isUserWithCode",
            favourites: "favorites/whichList",
            basket: "basket/basket",
            productsWithDefaultPrices: "basket/productsWithDefaultPrices",
            user: "profile/user",
            isAuthenticated: "auth/isAuthenticated",
            showNotifyLoader: "products/showNotifyLoader",
        }),
        canBePurchased() {
            return !(this.leftoverType.displayType === "out-stock") && !this.product.hasDuplicate;
        },
        /*unauthorised or authorised without code*/
        isRegularUser() {
            return !(this.isUserWithCode || this.isProfessional || this.isDistributor);
        },
        clientPriceTypeName() {
            return this.isProfessional ? this.$t("patient") : this.$t("cosmetologist");
        },
    },
    methods: {
        getProductPriceTitle,
        ...mapActions({
            addToFavorite: "favorites/ADD_PRODUCT_TO_FAVOURITES",
            fetchProduct: "card/GET_PRODUCT",
            fetchSameProduct: "card/GET_SAME_PRODUCTS",
            removeFromFavorite: "favorites/REMOVE_PRODUCT_IN_FAVORITE",
            getFavorites: "favorites/GET_FAVORITES_LIST",
            fetchDefaultPrices: "basket/GET_DEFAULT_PRICE_BASKET",
            fetchData: "products/SET_PRODUCT_PRODUCT_INFO",
        }),
        ...mapMutations({
            addToBasket: `basket/ADD_ITEM_TO_BASKET`,
            changeFastBuyPopup: "popups/CHANGE_FAST_BUY_POPUP",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeBasketPopup: "popups/CHANGE_BASKET_POPUP",
            showNotifyWhenAvailable: "popups/SHOW_NOTIFY_WHEN_AVAILABLE",
            changeSeminarDetailsPopup: "popups/CHANGE_SEMINAR_DETAILS_POPUP",
        }),
        checkValue() {
            const productPrices = this.product.productPrices.data;

            const productPrice = find(productPrices, productPrice => this.isSelectedPrice(productPrice));

            this.selectProductPrice(productPrice || productPrices[0]);
        },
        showNotify() {
            const showPopup = () => {
               this.changeSeminarDetailsPopup({status: true, payload: {
                    title: "",
                    detail: this.$t("thankYouForContactingUsAManagerWillContactYouSoon"),
               }});
            };

            const payload = {
                product_id: this.product.id,
                product_price_id: this.selectedProductPrice.id,
            };

            if (!this.isAuthenticated) {
                this.showNotifyWhenAvailable({status: true, payload});
            } else {
                this.fetchData(payload)
                    .then(showPopup)
                    .catch(showPopup);
            }
        },
        async loadSameProducts() {
            this.sameProducts = await this.fetchSameProduct(this.$route.params.slug);
        },
        async getProductPrice() {
            let ids = [this.product.id];
            await this.fetchDefaultPrices({ ids: ids });

            this.productsWithDefaultPrices.forEach(product => {
                this.clientPrices = product.productPrices.data;
            });
        },
        /**
         * @param {ProductPrice} productPrice
         */
        selectProductPrice(productPrice) {
            if (!this.leftoverType) {
                this.leftoverType = productPrice.leftoverType.data;
            }

            this.selectedProductPrice = productPrice;

            if (this.isSelectedPrice(productPrice)) {
                return;
            }

            this.leftoverType = productPrice.leftoverType.data;

            this.setSelectedCount(this.selectedCount, productPrice);
            this.setPriceValueToQuery(productPrice.value);
        },
        setPriceValueToQuery(value) {
            this.$router.replace({ query: { value } });
        },
        isSelectedPrice(productPrice) {
            return parseInt(this.$route.query.value) === parseInt(productPrice.value);
        },
        addImageProduct() {
            this.images = _(this.product.images.data)
                // cast from an observer array to a simple array
                .toArray()
                // prepend current product image
                .unshift({ link: this.selectedProductPrice.image })
                // remove with empty links
                .filter(({ link }) => link)
                // adn only uniq
                .uniqBy("link")
                .value();
        },

        changeFavorite() {
            if (this.user) {
                const index = this.favourites.find(e => e.productId === this.product.id);
                if (index) {
                    this.removeFromFavorite(index.id).then(() => {
                        this.getFavorites();
                        this.favoriteSelect = false;
                        this.$toasted.success(this.$t("successRemoveFromFavorite"));
                    });
                } else {
                    this.addToFavorite({ product_id: this.product.id }).then(() => {
                        this.favoriteSelect = true;
                        this.$toasted.success(this.$t("successAddToFavorites"));
                        this.getFavorites();
                    });
                }
            } else {
                this.changeLoginPopup(true);
            }
        },
        changeTab(val) {
            this.currentTab = val;
        },
        openFastBuy() {
            this.changeFastBuyPopup(true, {
                product: {
                    select_count: 1,
                    select_type: this.selectedProductPrice,
                    ...this.product,
                },
            });
        },
        changeCount(type, count) {
            // this.setSelectedCount(count)
            if (type === "decrement" && this.selectedCount > 1) {
                return this.setSelectedCount(this.selectedCount - 1);
            } else if (type === "increment") {
                return this.setSelectedCount(this.selectedCount + 1);
            } else {
                return this.setSelectedCount(count);
            }
        },
        setSelectedCount(count, productPrice = null) {
            this.selectedCount = 0;
            const setQuantity = Math.min(
                Math.max(Number.parseFloat(count || "1"), 1),
                (productPrice || this.selectedProductPrice).quantity
            );

            return (this.selectedCount = Math.ceil(setQuantity));
        },
        carouselNav($event, direction) {
            const carousel = this.$refs["card-carousel"];
            carousel.advancePage(direction);
        },
        addBasket() {
            this.addToBasket({
                select_count: this.selectedCount,
                product_price: this.selectedProductPrice,
                product: this.product,
            });
            this.changeBasketPopup(true);
            this.$toasted.success(this.$t("successAddBasket"));
        },
        /**
         * @param {ProductPrice} productPrice
         * @return {string}
         */
        getBasePriceForUser(productPrice) {
            if (this.isUserWithCode) {
                const clientProductPrice = find(this.clientPrices, price => price.value === productPrice.value);

                if (!clientProductPrice) {
                    return "0";
                }

                return clientProductPrice.price.toFixed(0);
            }

            if (this.priceHasDiscountForProfessional(productPrice)) {
                return productPrice.oldPrice.toFixed(0);
            }

            return "0";
        },
        showClientPriceColumn() {
            /*todo - isDistributor*/
            return (
                (this.isProfessional || this.isDistributor) &&
                some(this.clientPrices, ({ typeVisible }) => typeVisible)
            );
        },
        /**
         * @param {ProductPrice} productPrice
         * @return {boolean}
         */
        showClientPrice(productPrice) {
            /*todo - isDistributor*/
            return (
                (this.isProfessional || this.isDistributor) &&
                some(
                    this.clientPrices,
                    clientPrice => clientPrice.value === productPrice.value && clientPrice.typeVisible
                )
            );
        },
        /**
         * @param {ProductPrice} productPrice
         * @return {string}
         */
        getClientPrice(productPrice) {
            return (productPrice.clientPrice && productPrice.clientPrice !== 0
                ? productPrice.clientPrice
                : productPrice.price
            )?.toFixed(0);
        },
        productHasDiscountForSomePrice() {
            if (this.isProfessional || this.isDistributor) {
                return some(
                    this.product.productPrices.data,
                    productPrice => productPrice.hasIndividualDiscount || productPrice.hasActivePromotion
                );
            }

            return some(this.product.productPrices.data, productPrice => productPrice.hasActivePromotion);
        },
        /**
         * @param {ProductPrice} productPrice
         * @return {boolean}
         */
        priceHasDiscountForProfessional(productPrice) {
            /*todo - isDistributor*/
            if (!(this.isProfessional || this.isDistributor)) {
                return false;
            }

            return productPrice.hasIndividualDiscount || productPrice.hasActivePromotion;
        },
        professionalHasDiscount() {
            return (this.isProfessional || this.isDistributor) && this.productHasDiscountForSomePrice();
        },
    },
};
